







































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextAreaWithMode from '@/components/TextAreaWithMode.vue'
import {
  GroupDetail,
  ENameColor,
  EAvatarType,
  EEditMode,
  ETinyFileType
} from '@/models'
import scrollToError from '@/helpers/ScrollToError'
import GroupService from '@/services/GroupService'
import { EhumbNailsDisplay } from '@/models/Setting/Enum'
//@ts-ignore
import _ from 'lodash'
import updateGroupMaxFileSize from '@/helpers/UpdateGroupMaxFileSize'

/**
 * f2-101a
 * グループ設定　基本情報編集画面
 */
@Component({ components: { TextAreaWithMode } })
export default class GroupInfoEditPage extends Vue {
  //data
  private groupInfo: GroupDetail = new GroupDetail()
  private groupInfoCurrent: GroupDetail = new GroupDetail() //used for check before leave
  private defaultAvaSrc: IAvatarSrc[] = []
  private defaultAvatarPick: IAvatarSrc = new IAvatarSrc()
  private avatarFullSizeSrc: string = ''
  private avatarFullSizeBlob!: Blob
  private avatarCropperSrc: string = ''
  private chooseAvatarName: string = String(this.$t('common.form.not_selected'))
  private chooseAvatarTempName: string = ''
  private coverSrc: string = ''
  private coverBlob!: File
  private chooseCoverName: string = String(this.$t('common.form.not_selected'))
  private noticeMode: EEditMode = EEditMode.TEXT
  private noticeText: string = ''
  private noGroupAvatar =
    window.location.origin + require('@/assets/images/group/avatar_default.png')

  //validate
  private modalMess: string = ''
  private imageType: string[] = ['image/png', 'image/gif', 'image/jpeg']
  private errorAvatarTypeAndSize: boolean = false
  private errorCoverTypeAndSize: boolean = false
  private maxFileSize: any = {
    mb: Number(this.$store.state.userInfo.user.current_group_max_file_size),
    bytes:
      Number(this.$store.state.userInfo.user.current_group_max_file_size) *
      1048576
  }
  private validNotice: boolean = true

  //enum
  private eNameColor: any = ENameColor
  private eAvatarType: any = EAvatarType
  private eTinyFileType: any = ETinyFileType
  private ehumbNailsDisplay: any = EhumbNailsDisplay

  //hanlde before leave
  private confirmLeave: any = () => {}
  private confirmLeaveIgnore: boolean = false

  created() {
    this.importAllDefaultAvatar()
    this.getGroupInfoDetail(this.$route.params.groupId)
    this.setDefaultForAvatar()
    this.handleMaxFileSize()
  }

  async handleMaxFileSize() {
    await updateGroupMaxFileSize(this.$route.params.groupId)
    this.maxFileSize = {
      mb: Number(this.$store.state.userInfo.user.current_group_max_file_size),
      bytes:
        Number(this.$store.state.userInfo.user.current_group_max_file_size) *
        1048576
    }
  }

  checkRouterBeforLeave() {
    return (
      this.groupInfo.name === this.groupInfoCurrent.name &&
      this.groupInfo.notice === this.groupInfoCurrent.notice &&
      this.groupInfo.letter_color === this.groupInfoCurrent.letter_color &&
      this.groupInfo.icon_image === this.groupInfoCurrent.icon_image &&
      this.groupInfo.auto_delete_day ===
        this.groupInfoCurrent.auto_delete_day &&
      !this.coverBlob &&
      !this.avatarFullSizeBlob
    )
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (!this.confirmLeaveIgnore && !this.checkRouterBeforLeave()) {
      this.confirmLeave = next
      this.$bvModal.show('group-edit-modal-confirm-leave')
    } else {
      next()
    }
  }

  @Watch('avatarFullSizeSrc')
  setDefaultForAvatar() {
    if (!this.avatarFullSizeSrc) {
      this.avatarFullSizeSrc = this.noGroupAvatar
    }
  }

  @Watch('groupInfo.icon_image')
  watchUseageAvatar() {
    if (this.groupInfo.icon_image === EAvatarType.NONE) {
      this.avatarFullSizeSrc = ''
      this.chooseAvatarName = ''
    } else {
      this.avatarFullSizeSrc = this.groupInfo.icon_image_small_path
      this.chooseAvatarName = this.groupInfo.icon_file_name
    }
  }

  importAllDefaultAvatar() {
    GroupService.getGroupDefaultIcon().then(res => {
      if (res.status === 200) {
        this.defaultAvaSrc = res.data
      }
    })
  }

  selectDefaultAvatar(pickedAvatar: IAvatarSrc) {
    this.avatarFullSizeSrc = pickedAvatar.small_path
    this.defaultAvatarPick = pickedAvatar
  }

  getGroupInfoDetail(groupId: string) {
    GroupService.getGroupById(groupId)
      .then(res => {
        if (res.status === 200) {
          this.groupInfo = new GroupDetail(res.data)
          this.groupInfoCurrent = _.cloneDeep(this.groupInfo)
          this.avatarFullSizeSrc = this.groupInfo.icon_image_small_path
          this.coverSrc = this.groupInfo.header_image
          this.noticeMode = Number(this.groupInfo.notice?.startsWith('<'))
          this.chooseCoverName = this.groupInfo.header_file_name
          this.chooseAvatarName = this.groupInfo.icon_file_name

          if (this.noticeMode === EEditMode.TEXT) {
            this.noticeText = this.groupInfo.notice
          }
        }
      })
      .catch(err => {
        this.$bvModal.show('modal-error-group-edit')
      })
  }

  onAvatarChange(event: any) {
    let files = event.target.files || event.dataTransfer.files
    if (event.target.files.length !== 1) return

    //error file size
    if (files[0].size > this.maxFileSize.bytes) {
      this.modalMess = this.$t('groups.shared_folder.overwrite_error_msg', {
        max: this.maxFileSize.mb
      }) as string
      this.$bvModal.show('modal-info-group-edit')
      this.errorAvatarTypeAndSize = true
      return
    }

    //error file type
    if (!this.imageType.includes(files[0].type)) {
      this.modalMess = this.$t('common.message.upload_image_only') as string
      this.$bvModal.show('modal-info-group-edit')
      this.errorAvatarTypeAndSize = true
      return
    }

    //normal case
    this.errorAvatarTypeAndSize = false
    const blob = new Blob([files[0]])
    this.avatarCropperSrc = URL.createObjectURL(blob)
    this.chooseAvatarTempName = files[0].name
    this.$bvModal.show('cropAvatar')
  }

  onCoverChange(event: any) {
    let files = event.target.files || event.dataTransfer.files
    if (event.target.files.length !== 1) return

    //error file size
    if (files[0].size > this.maxFileSize.bytes) {
      this.modalMess = this.$t('groups.shared_folder.overwrite_error_msg', {
        max: this.maxFileSize.mb
      }) as string
      this.$bvModal.show('modal-info-group-edit')
      this.errorCoverTypeAndSize = true
      return
    }

    //error file type
    if (!this.imageType.includes(files[0].type)) {
      this.modalMess = this.$t('common.message.upload_image_only') as string
      this.$bvModal.show('modal-info-group-edit')
      this.errorCoverTypeAndSize = true
      return
    }

    //normal case
    this.errorCoverTypeAndSize = false
    const blob = new Blob([files[0]])
    this.coverSrc = URL.createObjectURL(blob)
    this.coverBlob = files[0]
    this.chooseCoverName = files[0].name
  }

  handleCropperImg() {
    const canvas = (this.$refs.avatarCropper as any).clip()
    canvas.toBlob((blob: Blob) => {
      this.avatarFullSizeSrc = URL.createObjectURL(blob)
      this.avatarFullSizeBlob = blob
      this.chooseAvatarName = this.chooseAvatarTempName
      this.$bvModal.hide('cropAvatar')
    })
  }

  handleValueNotice(value: any) {
    if (value.mode === EEditMode.TEXT) {
      this.groupInfo.notice = value.text
    } else {
      this.groupInfo.notice = value.html
    }
    this.noticeText = value.text
  }

  handleValidNotice(valid: boolean) {
    this.validNotice = valid
  }

  async onSubmitGroupInfo(valid: boolean) {
    if (
      valid &&
      !this.errorAvatarTypeAndSize &&
      !this.errorCoverTypeAndSize &&
      this.validNotice
    ) {
      this.confirmLeaveIgnore = true
      let formData = new FormData()
      formData.set('id', String(this.groupInfo.id))
      formData.set('name', this.groupInfo.name)
      formData.set('icon_image', String(this.groupInfo.icon_image))
      formData.set('icon_file_name', this.chooseAvatarName)
      formData.set('letter_color', String(this.groupInfo.letter_color))
      formData.set('notice', this.groupInfo.notice)
      formData.set('auto_delete_day', String(this.groupInfo.auto_delete_day))
      if (this.coverBlob) {
        formData.set('header_image_blob', this.coverBlob)
        formData.set('header_file_name', this.chooseCoverName)
      }
      this.handleIconImageChosse(formData)
      formData.append('_method', 'PATCH')
      this.$blockui.show()
      GroupService.updateGroupBasicInfo(formData)
        .then(res => {
          if (res.status === 200) {
            this.modalMess = this.$t('common.message.registered') as string
            this.$bvModal.show('modal-success')
          }
        })
        .catch(() => {
          this.modalMess = this.$t('common.message.register_fail') as string
          this.$bvModal.show('modal-error')
        })
        .finally(() => this.$blockui.hide())
    } else {
      scrollToError(this.$refs, 'groupBasicInfo')
    }
  }

  /**
   * Hanle group icon
   */
  handleIconImageChosse(formData: FormData) {
    switch (this.groupInfo.icon_image) {
      case EAvatarType.FILE_UPLOAD:
        if (this.avatarFullSizeBlob)
          formData.set('icon_image_blob', this.avatarFullSizeBlob)
        break
      case EAvatarType.DEFAULT:
        if (this.groupInfo.icon_image_small_path !== this.avatarFullSizeSrc) {
          formData.set('icon_image_path', this.defaultAvatarPick.path)
          formData.set(
            'icon_image_small_path',
            this.defaultAvatarPick.small_path
          )
        }
        break
      case EAvatarType.NONE:
        formData.set('icon_image_path', this.noGroupAvatar)
        formData.set('icon_image_small_path', this.noGroupAvatar)
        break
      default:
        break
    }
  }

  backToInfoPage() {
    this.$router.push({ name: 'group-setting-information' })
  }

  backToInfoPageRoute() {
    return ({ name: 'group-setting-information' })
  }
}

class IAvatarSrc {
  id!: number
  name: string = ''
  path: string = ''
  small_path: string = ''

  constructor(init?: Partial<IAvatarSrc>) {
    Object.assign(this, init)
  }
}
